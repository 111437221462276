import React from "react";
import { Link } from "react-router-dom";

/**
 * Renders the NotFoundPage component.
 *
 * @returns {JSX.Element} The rendered NotFoundPage component.
 */
const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Page Not Found</h1>
      <p>The page you're looking for doesn't exist or has been moved.</p>
      <Link to="/" className="link">
        Go to Home Page
      </Link>
    </div>
  );
};

export default NotFoundPage;
