const testimonials = [
  {
    firstName: "Ewa",
    lastName: "N.",
    content:
      "I was really unsure whether to go for the Samsung QLED 4K TV, but after reading the detailed review, I felt much more confident. The recommendation included pros, cons, and alternative options, which made the decision much easier. Highly recommend this service if you're looking for honest and thorough advice.",
    device: "Samsung QLED 4K TV",
    country: "Poland",
    color: "#0582F7",
  },
  {
    firstName: "Liam",
    lastName: "O.",
    content:
      "I had my eye on the LG Gram 17 laptop for a while, but after using this service, I got all the information I needed to make a final decision. The review highlighted exactly what I was looking for, including battery life and display quality. I'm so happy with my purchase!",
    device: "LG Gram 17 Laptop",
    country: "Ireland",
    color: "#FA6900",
  },
  {
    firstName: "Mariko",
    lastName: "T.",
    content:
      "I was considering getting a Panasonic microwave oven, but wasn't sure if it would meet my needs. The detailed feedback and comparison with other models really helped me see the benefits. I'm delighted with my new microwave!",
    device: "Panasonic Microwave Oven",
    country: "Japan",
    color: "#FDAC16",
  },
  {
    firstName: "Carlos",
    lastName: "G.",
    content:
      "I was on the fence about getting the Sony Alpha 7C camera. The review gave me a clear understanding of its performance, and I was able to see how it stacks up against other models in terms of image quality and portability. Totally worth the investment!",
    device: "Sony Alpha 7C Camera",
    country: "Spain",
    color: "#EEF1F3",
  },
  {
    firstName: "Alice",
    lastName: "B.",
    content:
      "The Bosch fridge freezer was exactly what I needed for my kitchen. The review was super detailed, showing all the features I cared about, like energy efficiency and storage capacity. Definitely helped me finalize my decision. Highly recommend!",
    device: "Bosch Fridge Freezer",
    country: "United Kingdom",
    color: "#DC2C53",
  },
  {
    firstName: "John",
    lastName: "S.",
    content:
      "I was uncertain about the new Dell XPS 13 laptop, but this service gave me a breakdown of all its pros and cons, along with some solid alternatives. It was exactly the info I needed to pull the trigger. Fantastic service!",
    device: "Dell XPS 13 Laptop",
    country: "United States",
    color: "#0582F7",
  },
  {
    firstName: "Nina",
    lastName: "S.",
    content:
      "Looking for a new fridge, I found the review of the Samsung Family Hub Refrigerator extremely helpful. The detailed feedback on its smart features and size options was exactly what I was looking for. I made the right choice and love the product!",
    device: "Samsung Family Hub Refrigerator",
    country: "Germany",
    color: "#3ECE82",
  },
  {
    firstName: "Jakub",
    lastName: "N.",
    content:
      "I was debating whether to buy a Huawei Mate 50 Pro phone, but the comprehensive review covered all aspects, from camera quality to performance, and gave me a good comparison to other options. The $3 I spent was totally worth it. Very satisfied with my purchase!",
    device: "Huawei Mate 50 Pro",
    country: "Poland",
    color: "#EEE9DF",
  },
  {
    firstName: "Sophia",
    lastName: "M.",
    content:
      "I had a hard time choosing between the Xiaomi Mi 11 and another high-end phone, but the review I received broke down everything I needed to know, from battery life to camera specs. Very helpful! I'm now loving my Xiaomi phone.",
    device: "Xiaomi Mi 11",
    country: "Canada",
    color: "#001236",
  },
  {
    firstName: "Marcus",
    lastName: "W.",
    content:
      "I was looking for a reliable coffee machine, and the Jura E8 was on my radar. The review provided an honest take on its features and performance, and the comparison with other models made it easy to decide. My mornings are so much better now with this coffee machine!",
    device: "Jura E8 Coffee Machine",
    country: "Switzerland",
    color: "#5B15CC",
  },
];

export default testimonials;
