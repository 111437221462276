import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaQuoteRight } from "react-icons/fa6";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import testimonials from "../../../utils/testimonials";
import styles from "./Testimonial.module.css";

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        goToNext();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [isMobile, currentIndex]);

  const goToNext = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        setIsAnimating(false);
      }, 500);
    }
  };

  const goToPrev = () => {
    if (!isAnimating && currentIndex > 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex - 1);
        setIsAnimating(false);
      }, 500);
    }
  };

  return (
    <>
      <Helmet>
        <title>Customer Testimonials - What Our Users Are Saying</title>
        <meta
          name="description"
          content="Read testimonials from our happy customers. Find out how our products have helped people make better tech decisions. Join the community!"
        />
        <meta
          name="keywords"
          content="customer testimonials, reviews, user feedback, tech product reviews, customer experiences, product reviews"
        />
        <meta
          property="og:title"
          content="Customer Testimonials - What Our Users Are Saying"
        />
        <meta
          property="og:description"
          content="Read testimonials from our happy customers. Find out how our products have helped people make better tech decisions. Join the community!"
        />
        <meta
          property="og:keywords"
          content="customer testimonials, reviews, user feedback, tech product reviews, customer experiences, product reviews"
        />
      </Helmet>

      <div className={styles.container}>
        <div className={styles.header}>What our customers say about us...</div>
        <div className={styles.testimonialContainer}>
          {!isMobile && (
            <button
              className={styles.arrowButton}
              onClick={goToPrev}
              disabled={currentIndex === 0 || isAnimating}
              aria-label="Chevron Left"
            >
              <FaArrowCircleLeft />
            </button>
          )}

          <div className={styles.testimonialWrapper}>
            <div
              className={styles.testimonialTrack}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
                transition: isAnimating ? "transform 0.5s ease-in-out" : "none",
              }}
            >
              {testimonials.map((testimonial, idx) => (
                <div className={styles.testimonial} key={idx}>
                  <div
                    className={styles.testimonialTile}
                    style={{ border: `8px solid ${testimonial.color}` }}
                  >
                    <FaQuoteRight />
                    <p className={styles.name}>
                      {testimonial.firstName} {testimonial.lastName}
                    </p>
                    <p className={styles.content}>{testimonial.content}</p>
                    <p className={styles.device}>
                      Product: {testimonial.device}
                    </p>
                    <p className={styles.country}>
                      Country: {testimonial.country}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          {!isMobile && (
            <button
              className={styles.arrowButton}
              onClick={goToNext}
              disabled={currentIndex === 4 || isAnimating}
              aria-label="Chevron Right"
            >
              <FaArrowCircleRight />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Testimonial;
