import React, { useEffect, useState, lazy, Suspense } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Helmet } from "react-helmet";
import Review from "../../components/picklynow/Review/Review";
import HowItWorks from "../../components/picklynow/HowItWorks/HowItWorks";
import Testimonial from "../../components/picklynow/Testimonial/Testimonial";
import FAQ from "../../components/picklynow/FAQ/FAQ";
import Contact from "../../components/picklynow/Contact/Contact";
import Footer from "../../components/picklynow/Footer/Footer";
import { useAppState } from "../../appState/AppStateContext";
import AboutUs from "../../components/picklynow/AboutUs/AboutUs";
import Pricing from "../../components/picklynow/Pricing/Pricing";
import Spinner from "../../components/common/Other/Spinner";
import CookieConsent from "react-cookie-consent";
import cookiesPolicy from "../../assets/files/cookies_policy_pickly_2024.pdf";

import styles from "./HomePage.module.css";

// Lazy load dla Bloga
const LazyBlog = lazy(() => import("../../components/picklynow/Blog/Blog"));

const HomePage = () => {
  const { state } = useAppState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [availabilityStatus, setAvailabilityStatus] = useState({
    reportError: false,
    openAIAPI: false,
    webPilotAPI: false,
  });

  useEffect(() => {
    const fetchAvailability = async () => {
      setIsLoading(true);
      setErrorStatus(false);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/availability/status`,
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          setAvailabilityStatus({
            reportError: response?.data?.isReportErrorResolved || false,
            openAIAPI: response?.data?.isOpenAIAvailable || false,
            webPilotAPI: response?.data?.isWebPilotAvailable || false,
          });
        }
      } catch (error) {
        console.log("Test");
        setErrorStatus(true);
        Sentry.captureException(error, {
          extra: {
            message: "Error while checking status",
            component: "HomePage",
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAvailability();
  }, []);

  if (isLoading) {
    return (
      <div className={`${styles.spinner} ${styles.centered}`}>
        <Spinner />
      </div>
    );
  }

  if (errorStatus) {
    return (
      // <div className={`${styles.error} ${styles.centered}`}>
      //   <p>Coś poszło nie tak. Spróbuj ponownie później.</p>
      // </div>
      <div className={`${styles.spinner} ${styles.centered}`}>
        <Spinner />
      </div>
    );
  }

  const { reportError, openAIAPI, webPilotAPI } = availabilityStatus;

  return (
    <div
      className={`${state.isLoggedIn ? "contentSignIn" : "contentSignOut"} ${
        state.isSidebarOpen ? "shifted" : ""
      }`}
    >
      <Helmet>
        <title>Home - Picklynow</title>
        <meta
          name="description"
          content="Welcome to Picklynow! Get expert advice and personalized tech recommendations on the latest gadgets and accessories."
        />
        <meta
          name="keywords"
          content="tech recommendations, personalized advice, gadgets, electronics, product reviews, tech support"
        />
        <meta
          property="og:title"
          content="Picklynow - Expert Tech Recommendations"
        />
        <meta
          property="og:description"
          content="Welcome to Picklynow! Get expert advice and personalized tech recommendations on the latest gadgets and accessories."
        />
        <meta property="og:url" content="https://www.picklynow.com" />
      </Helmet>
      <div id="home">
        <Review
          reportError={reportError}
          openAIAPI={openAIAPI}
          webPilotAPI={webPilotAPI}
          errorStatus={errorStatus}
        />
      </div>
      <div id="about">
        <AboutUs webPilotAPI={webPilotAPI} />
      </div>
      <div id="pricing">
        <Pricing webPilotAPI={webPilotAPI} />
      </div>
      <div id="steps">
        <HowItWorks webPilotAPI={webPilotAPI} />
      </div>
      <div id="opinions">
        <Testimonial />
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="contact">
        <Contact reportError={reportError} />
      </div>
      <div id="blog">
        {reportError && (
          <Suspense fallback={<div>Ładowanie bloga...</div>}>
            <LazyBlog />
          </Suspense>
        )}
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          background: "#fff",
          color: "#000",
          fontSize: "13px",
          borderRadius: "5px",
          cursor: "pointer",
          padding: "12px 25px",
        }}
        expires={365}
      >
        This website uses cookies for statistical and analytical purposes.
        <a
          href={cookiesPolicy}
          download="cookies_policy_pickly_2024.pdf"
          className={styles.cookies}
          style={{ paddingLeft: "5px" }}
        >
          Learn more...
        </a>
      </CookieConsent>
      <Footer />
    </div>
  );
};

export default HomePage;
