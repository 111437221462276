import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet"; // Import Helmet
import styles from "./SuccessAndCancel.module.css";

const Success = () => {
  const { transactionID } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("");

  const fetchTransactionStatus = async () => {
    setIsLoading(true);
    setErrorStatus(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/payments/transaction-status/${transactionID}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setTransactionStatus(response.data.state);
      }
    } catch (error) {
      setErrorStatus(true);
      Sentry.captureException(error, {
        extra: {
          message: "Error while checking status",
          component: "Review",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionStatus();
  }, []);

  return (
    <>
      <Helmet>
        <title>Payment Status - {transactionStatus}</title>
        <meta
          name="description"
          content={`The status of your payment with transaction ID ${transactionID} is ${transactionStatus}. Learn more about your payment process and next steps.`}
        />
        <meta
          name="keywords"
          content="payment status, transaction status, payment success, payment failed, payment cancelled, payment processing"
        />
        <meta
          property="og:title"
          content={`Payment Status - ${transactionStatus}`}
        />
        <meta
          property="og:description"
          content={`The status of your payment with transaction ID ${transactionID} is ${transactionStatus}. Learn more about your payment process and next steps.`}
        />
        <meta
          property="og:url"
          content={`https://yourwebsite.com/payment-status/${transactionID}`}
        />
      </Helmet>

      <div>
        <div className={styles.successContainer}>
          <div className={styles.content}>
            <h1 className={styles.title}>
              Payment status: {transactionStatus}
            </h1>
            {isLoading ? (
              <p className={styles.message}>Loading transaction status...</p>
            ) : errorStatus ? (
              <p className={styles.message}>
                There was an error while fetching the transaction status.
              </p>
            ) : transactionStatus === "new" ? (
              <p className={styles.message}>
                Your payment is being processed. Once we complete the process,
                the transaction status will change.
              </p>
            ) : transactionStatus === "canceled" ? (
              <p className={styles.message}>
                Unfortunately, your payment has been cancelled. We encountered
                issues while generating the report. Your payment will be
                refunded within 7 business days. Please check your payment
                method or contact support for more details. You can also try
                again.
              </p>
            ) : (
              <p className={styles.message}>
                Thank you for your payment! Your transaction was successful.{" "}
                <br />
                You should receive the report in your email within 24 hours. It
                usually takes a few minutes. <br />
              </p>
            )}
            {/* Displaying transaction ID clearly */}
            <div className={styles.transactionIdContainer}>
              <strong>Payment ID: </strong>
              <span className={styles.transactionId}>{transactionID}</span>
            </div>
            <br />
            <a href="/" className={styles.homeLink}>
              Return to Home
            </a>
            <a onClick={fetchTransactionStatus} className={styles.homeLink}>
              Refresh Status
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Success;
