const faqs = [
    {
      question: "What is this service about?",
      answer:
        "This service provides you with tailored recommendations to help you make informed purchasing decisions. We deliver a detailed report in PDF format.",
    },
    {
      question: "How quickly will I receive my recommendation?",
      answer:
        "You will receive your recommendation within minutes after completing your payment. The PDF report is sent directly to your email address, ensuring quick and seamless delivery.",
    },
    {
      question: "Can I trust the recommendations?",
      answer:
        "Yes, we aim to provide objective and helpful insights tailored to your specific product query.",
    },
    {
      question: "What types of products can I get recommendations for?",
      answer:
        "Our service covers a wide range of products, from electronics and gadgets to household items and more. If you're considering a purchase and need expert advice, we can help you.",
    },
    {
      question: "Is my payment information secure?",
      answer:
        "Absolutely. We use trusted and secure payment gateways like Stripe to ensure your financial information is protected. Your privacy and security are our top priorities.",
    },
    {
      question: "What if I don’t receive my recommendation?",
      answer:
        "If you don’t receive your recommendation within a few minutes, please check your spam or junk folder. If it’s still missing, contact our support team, and we’ll promptly assist you.",
    },
    {
      question: "Can I get a refund if I’m not satisfied?",
      answer:
        "Due to the nature of the service, we do not offer refunds. However, if there’s an issue with your recommendation, please contact support, and we’ll do our best to resolve it.",
    },
  ];
  
  export default faqs;
  
