import React from "react";
import ReactDOM from "react-dom/client";
import AppStateProvider from "./appState/AppStateProvider";
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import App from "./App";

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,

  tracePropagationTargets: [
    /^https:\/\/picklynow\.ovh\/api/,  
    /^http:\/\/localhost:3000\/api/,
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
    <AppStateProvider>
      <App />
    </AppStateProvider>
    </Router>
  </React.StrictMode>
);
