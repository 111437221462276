import React from "react";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet"; // Import Helmet for managing meta tags
import styles from "./SuccessAndCancel.module.css";

const Cancel = () => {
  return (
    <>
      <Helmet>
        <title>Payment Canceled - Picklynow</title>
        <meta
          name="description"
          content="Your payment has been canceled. If you believe this was a mistake, please try again or contact support for assistance."
        />
        <meta
          name="keywords"
          content="payment canceled, transaction canceled, payment failure, support, payment issues"
        />
        <meta property="og:title" content="Payment Canceled - Picklynow" />
        <meta
          property="og:description"
          content="Your payment has been canceled. If you believe this was a mistake, please try again or contact support for assistance."
        />
        <meta
          property="og:url"
          content="https://www.picklynow.com/payment-canceled"
        />
      </Helmet>

      <div>
        <div className={styles.successContainer}>
          <div className={styles.content}>
            <h1 className={styles.title}>Payment Canceled</h1>
            <p className={styles.message}>
              Your payment has been canceled. <br />
              If you believe this was a mistake, please try again or contact
              support for assistance.
            </p>
            <a href="/" className={styles.homeLink}>
              Return to Home
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Cancel;
